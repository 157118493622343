import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/src/components/layout.js";
import { Link } from "gatsby";
import SEO from "../components/seo";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="RLE" mdxType="SEO" />
    <h1>{`Пишем функцию RLE для компрессии строки`}</h1>
    <p>{`В этом уроке мы рассмотрим решение задачи по компрессии строки с помощью кодирования повторов.
Такое кодирование называется RLE (Run-length encoding). Сутью алгоритма является замена повторяющихся символов
цифрой, обозначающей количество повторов. Кодирование чаще всего применяется для данных, которые
содержат большое количество серий одинаковых символов, например, иконки и графические рисунки.`}</p>
    <p>{`Умение решать эту задачу может пригодиться разработчику как на собеседовании, так и во время работы
со строками на реальных проектах.`}</p>
    <h2>{`Условия задачи`}</h2>
    <p>{`Дана строка, состоящая из букв A-Z: `}<inlineCode parentName="p">{`'AAAABBBCCXYZDDDDEEEFFFAAAAAABBBBBBBBBBBBBBBBBBBBBBBBBBBB'`}</inlineCode></p>
    <p>{`Нужно написать функцию, которая:`}</p>
    <ul>
      <li parentName="ul">{`на выходе вернет строку вида `}<inlineCode parentName="li">{`'A4B3C2XYZD4E3F3A6B28'`}</inlineCode></li>
      <li parentName="ul">{`сгенерирует любую ошибку, если на вход пришла невалидная строка`}</li>
    </ul>
    <p>{`Дополнительные пояснения:`}</p>
    <ul>
      <li parentName="ul">{`eсли символ встречается 1 раз, он остается без изменений`}</li>
      <li parentName="ul">{`если символ повторяется более 1 раза, к нему добавляется количество повторений`}</li>
    </ul>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function rle(str) {
  // your code 
}
`}</code></pre>
    <h2>{`Подготовка`}</h2>
    <p>{`Перед тем, как приступить к задаче, нужно убедится в том, что условия прозрачны и дополнительных
вопросов и узких кейсов нет. Добавим несколько кейсов, которые помогут валидировать решение.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`
console.log(rle('AAAABBBCCXYZDDDDEEEFFFAAAAAABBBBBBBBBBBBBBBBBBBBBBBBBBBB')) // 'A4B3C2XYZD4E3F3A6B28'
console.log(rle('ABC')) // 'ABC'
console.log(rle('')) // ''
console.log(rle('A4B3C2XYZD4E3F3A6B28')) // Error
`}</code></pre>
    <p>{`На этом этапе также стоит обдумать пути решения задачи и предположить ожидаемую
алгоритмическую сложность. Мы будем писать решение с линейной алгоритмической
сложностью `}<inlineCode parentName="p">{`O(n)`}</inlineCode>{`, то есть скорость выполнения будет напрямую зависеть
от длины строки.`}</p>
    <p>{`Решим задачу путем последовательного перебора символов и использования указателей
для хранения текущего состояния перебора. Понадобится 3 указателя для хранения
промежуточного результата, текущей буквы и количества повторов.`}</p>
    <h2>{`Решение`}</h2>
    <p>{`В первую очередь на раннем этапе обработаем редкие кейсы, когда будем возвращать
ошибку или пустую строку.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function rle(str) {
  if (str === "") return str;

  if (!str.match(/^[A-Z]+$/)) {
    throw new Error(\`'\${str}' is invalid input for rle()\`);
  }

  // ...
}

`}</code></pre>
    <p>{`Объявим переменные-указатели для хранения промежуточного состояния с начальными значениями.
Для получения символа можно использовать метод `}<inlineCode parentName="p">{`charAt`}</inlineCode>{`. Он повышает читаемость кода,
указывая на то, что в переменной строка. Этот метод работает даже в IE7, в отличие
от обращения по индексу. Однако, обращение по индексу `}<inlineCode parentName="p">{`str[index]`}</inlineCode>{` тоже ипользуется
повсеместно.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function rle(str) {
  // ...

  let result = "",
    current = str.charAt(0),
    count = 1;
     
  // ...
}
`}</code></pre>
    <p>{`Линейно итерируем по строке, начиная со второго символа. Первый у нас уже выбран
как текущий. Сравниваем текущий символ с символом из итерации. Если они разные, записываем
текущий символ в финальную строку. При наличии более одного повтора добавляем к символу количество
повторов. Если символы совпадают, итерируем дальше и увеличиваем счетчик повторов.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function rle(str) {
  // ...

  for (let i = 1; i < str.length; i++) {
    if (str.charAt(i) !== current) {
      result += current;
      if (count > 1) {
        result += count;
        count = 1;
      }
      current = str.charAt(i);
    } else {
      count++;
    }
  }

  // ...
}
`}</code></pre>
    <p>{`После выполнения цикла текущая буква в строку не попадает, поэтому перед возвратом
финальной строки нужно её добавить. В результате функция выглядит следующим образом:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`function rle(str) {
  if (str === "") return str;

  if (!str.match(/^[A-Z]+$/)) {
    throw new Error(\`'\${str}' is invalid input for rle()\`);
  }

  let result = "",
    current = str.charAt(0),
    count = 1;

  for (let i = 1; i < str.length; i++) {
    if (str.charAt(i) !== current) {
      result += current;
      if (count > 1) {
        result += count;
        count = 1;
      }
      current = str.charAt(i);
    } else {
      count++;
    }
  }

  result += current;

  return result;
}
`}</code></pre>
    <p>{`Помимо оценки сложности алгоритма по скорости выполнения, оценивают и сложность по
используемой памяти. При увеличении длины входной строки количество используемых
переменных не увеличивается, поэтому временная сложность по памяти этой
функции - `}<inlineCode parentName="p">{`O(1)`}</inlineCode>{`, то есть константная.`}</p>
    <h2>{`Проработка`}</h2>
    <p>{`Решите задачу, пройдя все подготовленные тесты.`}</p>
    <iframe src="https://codesandbox.io/embed/rle-eo0fp?fontsize=14&hidenavigation=1&previewwindow=tests&theme=dark" style={{
      "width": "100%",
      "height": "500px",
      "border": "0",
      "borderRadius": "4px",
      "overflow": "hidden"
    }} title="RLE" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>
    <Link to="/" mdxType="Link">Вернуться на главную</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      